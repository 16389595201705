import { graphql } from "gatsby"
import React from "react"

import "./profile.scss"

import Layout from "../components/layout"
import SEO from "../components/seo"

export const pageQuery = graphql`
  query profileQuery {
    allDirectusProfile {
      nodes {
        about
        contact
      }
    }
  }
`

const Contact = ({ data: { allDirectusProfile: profile } }) => (
  <Layout>
    <SEO title="Contact |" />

    <div className="profile">

      <div className="profile__title">Contact</div>
      <div className="profile__copy">© 2020</div>

      <div className="profile__container">
        <div className="profile__about">
          <div dangerouslySetInnerHTML={{ __html: profile.nodes[0].about }} />
        </div>
        <div className="profile__contact">
          <div dangerouslySetInnerHTML={{ __html: profile.nodes[0].contact }} />
        </div>
      </div>
    </div>
  </Layout>
)

export default Contact
